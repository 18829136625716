<template>
  <footer>
    <div class="container">
      <div class="brands">
        <div>
          <img
            src="@/assets/images/footer-1.png"
            alt="Home be like logo">
          <img
            src="@/assets/images/footer-2.png"
            alt="Mastercard secure code logo">
          <img
            src="@/assets/images/footer-3.png"
            alt="Verified by visa logo">
        </div>
      </div>
      <p class="copy">
        © Copyright 2020 HBL Cap cana All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      languageSelected: '',
      toggleMenu: false,
    };
  },
  methods: {
    ...mapActions([
      'updateLanguage',
    ]),
    updateLang() {
      this.updateLanguage(this.languageSelected);
    },
  },
  created() {
    // this.$localStorage.remove('user');
    this.languageSelected = this.getLanguage;
  },
};
</script>
