<template>
  <div>
    <div id="app">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        :opacity="1"
        color="#11568C"
        :width="150"
        :height="150" />
      <router-view :key="$route.fullPath" />
    </div>
    <SignIn v-show="getSignInModalStatus"
            @update="onUpdate" />
    <SignUp v-if="getSignUpModalStatus" />
    <ForgotPassword v-if="getForgotPasswordModalStatus" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import SignIn from '@/components/SignIn.vue';
import SignUp from '@/components/SignUp.vue';
import ForgotPassword from '@/components/ForgotPassword.vue';
// import LangSelect from '@/components/LangSelect.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    SignIn,
    SignUp,
    ForgotPassword,
    Loading,
    // LangSelect,
  },
  data() {
    return {
      languageSelected: '',
      toggleMenu: false,
      messagesCounter: 0,
      favoritesPropertiesCounter: 0
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'HomeBeLike',
    // all titles will be injected into this template
    titleTemplate: '%s | HomeBeLike',
  },
  computed: {
    ...mapGetters([
      'getLanguage',
      'getUser',
      'getSignInModalStatus',
      'getSignUpModalStatus',
      'getForgotPasswordModalStatus',
      'getCurrentRouteName',
    ]),
    computedMessageCounter() {
      return this.$store.getters.getMessageCounter;
    },
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
    computedFavoritesPropertiesCounter() {
      return this.getUser ? this.getFavoritesPropertiesCounter() : this.getFavoritesPropertiesLocalStorage();
    }
  },
  methods: {
    ...mapActions([
      'updateLanguage',
      'updateCurrentRouteName',
      'requestFavoritesProperties',
      'requestLogOut'
    ]),
    ...mapGetters([
      'getFavoritesPropertiesCounter',
      'getFavoritesPropertiesLocalStorage',
    ]),
    updateLang() {
      this.updateLanguage(this.languageSelected);
    },
    onUpdate() {
      this.toggleMenu = false;
    },
    showMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    logOut() {
      this.requestLogOut().then(() => {
        this.$router.push('/');
        window.location.reload();
      });
    }
  },
  created() {
    this.languageSelected = this.getLanguage;
    this.updateCurrentRouteName(this.$route.name);
  },
  mounted() {
    if ((this.getUser && this.getUser.groups.length > 0 && this.getUser.groups.find(obj => obj.code === 'hbl-concierge'))) {
      this.$axios.get('/v1/account/messages').then((response) => {
        this.messagesCounter = response.data.data.new_messages;
        this.$store.commit('setMessageCounter', response.data.data.new_messages);
      });
    }
    Promise.all([this.getUser ? this.requestFavoritesProperties() : this.getFavoritesPropertiesLocalStorage()]).then((response) => {
      const favoriteProperties = response[0] && response[0].data ? response[0].data.data : response[0];
      if (favoriteProperties) {
        favoriteProperties.forEach((property, index) => {
          favoriteProperties[index].isFavorite = true;
        }, favoriteProperties);
      }

      this.favoritesPropertiesCounter = favoriteProperties && favoriteProperties.length ? favoriteProperties.length : 0;
      // this.$nextTick(() => {
      //   this.$store.commit('setIsLoading', false);
      // });
    });
    // this.$store.commit('setIsLoading', false);
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.eventOnClick = (event) => {
          const dragging = el.getAttribute('data-dragging');
          if (!(el === event.target || el.contains(event.target)) && !dragging) vnode.context[binding.expression](event);
        };
        document.addEventListener('click', el.eventOnClick);
      },
      unbind(el) {
        document.removeEventListener('click', el.eventOnClick);
      },
    },
  },
  watch: {
    $route(to) {
      this.updateCurrentRouteName(this.$route.name);
      if (to.name === 'results') {
        this.$store.commit('setIsLoading', true);
      }
      this.toggleMenu = false;
      if (this.$store.getters.getCurrentRouteName !== 'results' && this.$store.getters.getCurrentRouteName !== 'property' && this.$store.getters.getCurrentRouteName !== 'user-booking-request') {
        this.$localStorage.remove('check_in');
        this.$localStorage.remove('check_out');
      }
    },
  },
};
</script>

<style>
#app {
  position: relative;
  min-height: 100vh;
}
.fix-margin {
  margin-top:-0.8rem;
}
.smoothTransition {
  transition: all .5s linear;
  display: block!important;
}
.d-block {
  display: block !important;
}
</style>
