import Vue from 'vue';
import Vuex from 'vuex';
import VueLocalStorage from 'vue-localstorage';
import axios from 'axios';

[Vuex, VueLocalStorage].forEach(x => Vue.use(x));

function cleanStorage() {
  Vue.localStorage.remove('user');
  Vue.localStorage.remove('token');
  Vue.localStorage.remove('bookingTrip');
  Vue.localStorage.remove('booking');
  Vue.localStorage.remove('language');
  Vue.localStorage.remove('favoriteProperties');
  Vue.localStorage.remove('check_in');
  Vue.localStorage.remove('check_out');
}

export default new Vuex.Store({
  state: {
    isSignInModalStatus: false,
    isSignUpModalStatus: false,
    isForgotPasswordModalStatus: false,
    currentRouteName: '',
    homeSlides: {},
    categories: {},
    properties: {},
    property: {},
    favoritesPropertiesCounter: 0,
    favoritesProperties: {},
    favoritesPropertiesLocalStorage: JSON.parse(Vue.localStorage.get('favoritesProperties')),
    countries: {},
    user: Vue.localStorage.get('user') ? JSON.parse(Vue.localStorage.get('user')) : null,
    language: Vue.localStorage.get('language') ? Vue.localStorage.get('language') : 'en',
    notificationsFields: {},
    foodInventory: {},
    places: {},
    posts: {},
    post: {},
    booking: JSON.parse(Vue.localStorage.get('booking')),
    bookingTrip: null,
    token: {
      useCredentails: true,
      headers: { Authorization: `Bearer ${Vue.localStorage.get('token')}`, 'Content-type': 'application/x-www-form-urlencoded' },
    },
    messageCounter: 0,
    isLoading: false,
  },
  getters: {
    getSignInModalStatus: state => state.isSignInModalStatus,
    getSignUpModalStatus: state => state.isSignUpModalStatus,
    getForgotPasswordModalStatus: state => state.isForgotPasswordModalStatus,
    getCurrentRouteName: state => state.currentRouteName,
    getHomeSlides: state => state.homeSlides,
    getCategories: state => state.categories,
    getProperties: state => state.properties,
    getProperty: state => state.property,
    getUser: state => state.user,
    getFavoritesPropertiesCounter: state => state.favoritesPropertiesCounter,
    getFavoritesProperties: state => state.favoritesProperties,
    getFavoritesPropertiesLocalStorage: state => state.favoritesPropertiesLocalStorage,
    getCountries: state => state.countries,
    getLanguage: state => state.language,
    getNotificationsFields: state => state.notificationsFields,
    getFoodInventory: state => state.foodInventory,
    getPlaces: state => state.places,
    getPosts: state => state.posts,
    getPost: state => state.post,
    getToken: state => state.token,
    getBooking: state => state.booking,
    getBookingTrip: state => state.bookingTrip,
    getMessageCounter: state => state.messageCounter,
    getIsLoading: state => state.isLoading,
  },
  mutations: {
    updateSignInModalStatus(state, status) {
      this.state.isSignInModalStatus = status;
    },
    updateSignUpModalStatus(state, status) {
      this.state.isSignUpModalStatus = status;
    },
    updateForgotPasswordModalStatus(state, status) {
      this.state.isForgotPasswordModalStatus = status;
    },
    updateCurrentRouteName(store, name) {
      this.state.currentRouteName = name;
    },
    setHomeSlides(state, slides) {
      this.state.homeSlides = slides;
    },
    setCategories(state, categories) {
      this.state.categories = categories;
    },
    setProperties(state, properties) {
      this.state.properties = properties;
    },
    setProperty(state, property) {
      this.state.property = property;
    },
    setUser(state, user) {
      Vue.localStorage.set('user', JSON.stringify(user));
      this.state.user = JSON.parse(Vue.localStorage.get('user'));
      this.state.isSignInModalStatus = false;
    },
    setSignUp(state, user) {
      Vue.localStorage.set('user', user);
      this.state.user = Vue.localStorage.get('user');
    },
    setLogOut() {
      this.state.user = null;
    },
    setFavoritesProperties(state, response) {
      this.state.favoritesProperties = response;
    },
    setFavoritesPropertiesCounter(state, counter) {
      this.state.favoritesPropertiesCounter = counter;
    },
    setFavoritesPropertiesLocalStorage(state, favoritesProperties) {
      this.state.favoritesPropertiesLocalStorage = favoritesProperties;
    },
    setCountries(state, response) {
      this.state.countries = response;
    },
    setPlaces(state, response) {
      this.state.places = response;
    },
    setLanguage(state, lang) {
      Vue.localStorage.set('language', lang);
      this.state.language = lang;
    },
    setNotificationsFields(state, response) {
      this.state.notificationsFields = response;
    },
    setFoodInventory(state, response) {
      this.state.foodInventory = response;
    },
    setPosts(state, response) {
      this.state.posts = response;
    },
    setPost(state, response) {
      this.state.post = response;
    },
    setToken(state, token) {
      this.state.token = {
        useCredentails: true,
        headers: { Authorization: `Bearer ${token}`, 'Content-type': 'application/x-www-form-urlencoded' },
      };
    },
    setBooking(state, response) {
      Vue.localStorage.set('booking', JSON.stringify(response));
      this.state.booking = JSON.parse(Vue.localStorage.get('booking'));
    },
    setBookingTrip(state, response) {
      this.state.bookingTrip = response;
    },
    setMessageCounter(state, value) {
      this.state.messageCounter = value;
    },
    setIsLoading(state, value) {
      this.state.isLoading = value;
    },
  },
  actions: {
    updateSignInModalStatus: (context, status) => context.commit('updateSignInModalStatus', status),
    updateSignUpModalStatus: (context, status) => context.commit('updateSignUpModalStatus', status),
    updateForgotPasswordModalStatus: (context, status) => context.commit('updateForgotPasswordModalStatus', status),
    updateCurrentRouteName: (context, name) => context.commit('updateCurrentRouteName', name),
    async requestHomeSlides(store) {
      // { headers: { lang: 'en' } }
      const slides = await axios.get('/v1/sliders/2').then();
      store.commit('setHomeSlides', slides.data[0]);
      return store.state.homeSlides;
    },
    async requestCategories(store) {
      const categories = await axios.get('/v1/categories');
      store.commit('setCategories', categories.data);
      return store.state.categories;
    },
    async requestProperties(store, config) {
      const limit = config.limit ? config.limit : 100;
      const page = config.page ? config.page : 1;
      const sortField = config.sort_field ? config.sort_field : 'created_at';
      const sortOrder = config.sort_order ? config.sort_order : 'DESC';
      const travelers = config.travelers ? config.travelers.value : 1;
      const destinationType = config.destination && config.destination.type ? config.destination.type : 'state';
      const destinationID = '647';
      const categoryID = config.category_id ? config.category_id : '';
      const lat = config.lat ? config.lat : '';
      const lng = config.lng ? config.lng : '';
      const zoom = config.zoom ? config.zoom : 10;
      const minPrice = config.range_price && config.range_price.length > 0 ? config.range_price[0] : 0;
      const maxPrice = config.range_price && config.range_price.length > 0 ? config.range_price[1] : 0;
      let checkIn = '';
      let checkOut = '';
      if (config.check_in && config.check_out) {
        const dateTimeFormatCheckIn = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'numeric', day: '2-digit' });
        const [{ value: checkInMonth },, { value: checkInDay },, { value: checkInYear }] = dateTimeFormatCheckIn.formatToParts(config.check_in);
        const dateTimeFormatCheckOut = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'numeric', day: '2-digit' });
        const [{ value: checkOutMonth },, { value: checkOutDay },, { value: checkOutYear }] = dateTimeFormatCheckOut.formatToParts(config.check_out);
        checkIn = config.check_in ? `${checkInYear}-${checkInMonth}-${checkInDay}` : '';
        checkOut = config.check_out ? `${checkOutYear}-${checkOutMonth}-${checkOutDay}` : '';
      }
      const properties = await axios.get(`/v2/properties?limit=${limit}&page=${page}&sort_field=${sortField}&sort_order=${sortOrder}&travelers=${travelers}&destination_type=${destinationType}&destination_id=${destinationID}&category_id=${categoryID}&lat=${lat}&lng=${lng}&zoom=${zoom}&min_price=${minPrice}&max_price=${maxPrice}&check_in=${checkIn}&check_out=${checkOut}`);
      setTimeout(() => {
        store.commit('setIsLoading', false);
      }, 500);
      store.commit('setProperties', properties.data);
      return store.state.properties;
    },
    async requestProperty(store, propertyID) {
      const property = await axios.get(`/v2/properties/${propertyID}`);
      store.commit('setProperty', property);
      return store.state.property;
    },
    async requestLogIn(store, user) {
      cleanStorage();
      const formData = new FormData();
      formData.append('email', user.email);
      formData.append('password', user.password);
      await axios.post('/api/login', formData).then((response) => {
        const localStorageFavoritesProperties = JSON.parse(Vue.localStorage.get('favoritesProperties')) ? JSON.parse(Vue.localStorage.get('favoritesProperties')) : null;
        Vue.localStorage.set('user', JSON.stringify(response.data));
        if (localStorageFavoritesProperties) {
          localStorageFavoritesProperties.forEach((property) => {
            store.dispatch('updateFavoritesProperties', property.id);
          });
          Vue.localStorage.remove('favoritesProperties');
        }
        Vue.localStorage.set('token', response.data.token);
        store.commit('setToken', Vue.localStorage.get('token'));
        axios.get('/v1/account/profile', { headers: { Authorization: `Bearer ${Vue.localStorage.get('token')}`, 'Content-type': 'application/x-www-form-urlencoded' } }).then((profile) => {
          store.commit('setUser', profile.data.data);
          Promise.resolve();
        });
      }).catch(error => Promise.reject(error));
    },
    async requestSignUp(store, user) {
      cleanStorage();
      const formData = new FormData();
      formData.append('email', user.email);
      formData.append('password', user.password);
      formData.append('password_confirmation', user.password);
      const response = await axios.post('/api/signup', formData);
      let returning;
      if (response.data.message) {
        returning = response.data.message;
      } else {
        const profile = new FormData();
        profile.append('name', user.name);
        profile.append('surname', user.surname);
        profile.append('country_id', user.country.value);
        profile.append('_method', 'put');
        Vue.localStorage.set('token', response.data.token);
        store.commit('setToken', response.data.token);
        const profileResponse = await axios.post('/v1/account/profile', profile, { headers: { Authorization: `Bearer ${Vue.localStorage.get('token')}`, 'Content-type': 'application/x-www-form-urlencoded' } });
        const localStorageFavoritesProperties = JSON.parse(Vue.localStorage.get('favoritesProperties')) ? JSON.parse(Vue.localStorage.get('favoritesProperties')) : null;
        Vue.localStorage.set('user', JSON.stringify(response.data));
        if (localStorageFavoritesProperties) {
          localStorageFavoritesProperties.forEach((property) => {
            store.dispatch('updateFavoritesProperties', property.id);
          });
          Vue.localStorage.remove('favoritesProperties');
        }
        // Vue.localStorage.set('user', JSON.stringify(profileResponse.data.data));
        store.commit('setUser', profileResponse.data.data);
        returning = store.state.user;
      }
      return returning;
    },
    async requestLogOut(store) {
      cleanStorage();
      store.commit('setLogOut');
      return store.state.user;
    },
    async requestFavoritesProperties(store) {
      let favoritesProperties = {};
      await axios.get('/v1/account/favorites').then((response) => {
        favoritesProperties = response;
        store.commit('setFavoritesPropertiesCounter', response.data.data.length);
      }).catch(() => {
        favoritesProperties = {};
      });
      store.commit('setFavoritesProperties', favoritesProperties);
      return store.state.favoritesProperties;
    },
    async updateFavoritesProperties(store, propertyID) {
      const formData = new FormData();
      let favoritesProperties = {};
      formData.append('property_id', propertyID);
      favoritesProperties = await axios.post('/v1/account/favorites', formData);
      await axios.get('/v1/account/favorites').then((response) => {
        store.commit('setFavoritesPropertiesCounter', response.data.data.length);
      }).catch(() => {
        favoritesProperties = {};
      });
      store.commit('setFavoritesProperties', favoritesProperties.data || favoritesProperties);
      return store.state.favoritesProperties;
    },
    async updateUserProfile(store, userUpdate) {
      const user = JSON.parse(Vue.localStorage.get('user'));
      const formData = new FormData();
      formData.append('id', user.id);
      formData.append('name', userUpdate.name);
      formData.append('surname', userUpdate.surname);
      formData.append('email', userUpdate.email);
      formData.append('area_code', userUpdate.phoneCode);
      formData.append('phone', userUpdate.phone);
      formData.append('gender', userUpdate.gender);
      formData.append('country_id', userUpdate.country);
      formData.append('school', userUpdate.school);
      formData.append('company', userUpdate.work);
      formData.append('_method', 'put');
      formData.append('birthday', `${userUpdate.birthday.year}/${userUpdate.birthday.month}/${userUpdate.birthday.day}`);
      if (userUpdate.avatar && userUpdate.avatar.path && userUpdate.avatar.path.length > 300) {
        formData.append('avatar', userUpdate.avatar.path);
      }
      const response = await axios.post('/v1/account/profile', formData);
      store.commit('setUser', response.data.data);
      return store.state.user;
    },
    async updateUserPassword(store, userPassword) {
      const formData = new FormData();
      formData.append('old_password', userPassword.oldPassword);
      formData.append('password', userPassword.newPassword);
      formData.append('_method', 'put');
      axios.post('/v1/account/profile', formData);
    },
    async updateFavoritesPropertiesLocalStorage(store, property) {
      const favoritesProperties = Vue.localStorage.get('favoritesProperties') ? JSON.parse(Vue.localStorage.get('favoritesProperties')) : [];
      const matchProperties = favoritesProperties.findIndex(prop => prop.id === property.id);
      if (matchProperties !== -1) favoritesProperties.splice(matchProperties, 1);
      else favoritesProperties.push(property);
      Vue.localStorage.set('favoritesProperties', JSON.stringify(favoritesProperties));
      await store.commit('setFavoritesPropertiesLocalStorage', JSON.parse(Vue.localStorage.get('favoritesProperties')));
      return store.state.favoritesPropertiesLocalStorage;
    },
    async requestCountries(store) {
      const response = await axios.get('/v1/locations/countries');
      store.commit('setCountries', response.data.data);
      return store.state.countries;
    },
    async requestPlaces(store, data) {
      const response = await axios.get(`/v1/locations/places?query=${escape(data.search)}&destination_type=${data.destination ? data.destination.type : ''}&destination_id=${data.destination ? data.destination.id : ''}`);
      store.commit('setPlaces', response.data.data);
      return store.state.places;
    },
    async updateLanguage(store, lang) {
      store.commit('setLanguage', lang);
      return store.state.language;
    },
    async requestNotificationsFields(store) {
      const response = await axios.get('/v1/contactcenter/notifications');
      store.commit('setNotificationsFields', response.data);
      return store.state.notificationsFields;
    },
    async requestFoodInventory(store) {
      const response = await axios.get('/v1/inventories/products');
      store.commit('setFoodInventory', response.data.data);
      return store.state.foodInventory;
    },
    async requestPosts(store, categoryID = null) {
      const categoryString = categoryID ? `?category_id=${categoryID}` : '';
      const posts = await axios.get(`/v1/posts/${categoryString}`);
      store.commit('setPosts', posts);
      return store.state.posts;
    },
    async requestPost(store, postID) {
      const post = await axios.get(`/v1/posts/${postID}`);
      store.commit('setPost', post);
      return store.state.post;
    },
    async requestBooking(store, bookingParams) {
      const formData = new FormData();
      formData.append('property_id', bookingParams.property_id);
      formData.append('check_in', bookingParams.check_in);
      formData.append('check_out', bookingParams.check_out);
      formData.append('travelers', bookingParams.travelers.value);
      const endpoint = store.state.user ? '/v2/bookings' : '/v2/bookings/guest';
      await axios.post(endpoint, formData).then((response) => {
        store.commit('setBooking', response.data.data);
        Promise.resolve();
      }).catch(error => Promise.reject(error));
    },
    async requestBookingTrip(store, bookingID) {
      await axios.get(`/v1/bookings/${bookingID}`).then((response) => {
        store.commit('setBookingTrip', response.data.data);
      }).catch(error => Promise.reject(error));
    },
  },
});
