<template>
  <div>
    <div class="modal fade show d-block"
         id="loginModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Sign in
            </h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="$store.dispatch('updateSignInModalStatus', false)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="signInForm">
              <div class="form-group has-icon">
                <i class="icon-user" />
                <input v-model="user.email"
                       v-validate="'required|email'"
                       name="email"
                       type="text"
                       class="form-control"
                       placeholder="Email">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('email')">{{ errors.first('email') }}</span>
              <div class="form-group has-icon">
                <i class="icon-lock" />
                <button type="button"
                        class="showPass"
                        @click="switchVisibility">
                  {{ user.passwordFieldType == 'password' ? 'Show' : 'Hide' }}
                </button>
                <input v-model="user.password"
                       v-validate="'required'"
                       name="password"
                       :type="user.passwordFieldType"
                       ref="password"
                       class="form-control"
                       placeholder="Password">
              </div>
              <a href="javascript:;"
                 class="btn btn-sm mb-3 float-right"
                 @click="$store.dispatch('updateSignInModalStatus', false); $store.dispatch('updateForgotPasswordModalStatus', true);"><strong>Forgot Password?</strong></a>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('password')">{{ errors.first('password') }}</span>
              <button class="btn btn-primary btn-block btn-lg">
                SIGN IN
              </button>
            </form>
          </div>
          <p class="text-danger"
             v-if="getMessage">
            {{ getMessage }}
          </p><br>
          <div class="modal-footer">
            <p>
              Don't you have an account yet? <br>
              <!-- <router-link v-if="this.$store.getters.getCurrentRouteName !== 'sign-up'"
                           :to="{ name: 'sign-up' }">
                REGISTER NOW
              </router-link> -->
              <a href="javascript:;"
                 @click="$store.dispatch('updateSignInModalStatus', false); $store.dispatch('updateSignUpModalStatus', true);">REGISTER NOW</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" />
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  data() {
    return {
      message: '',
      user: {
        email: null,
        password: null,
        passwordFieldType: 'password',
      },
    };
  },
  computed: {
    getMessage() {
      return this.message;
    },
  },
  methods: {
    signInForm() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$store.dispatch('requestLogIn', this.user).then(() => {
            const user = JSON.parse(this.$localStorage.get('user'));
            this.$emit('update', false);
            if (this.$localStorage.get('token')) {
              this.$axios.defaults.headers.common.Authorization = `Bearer ${this.$localStorage.get('token')}`;
            }
            if ((user && user.user.groups.length > 0 && user.user.groups.find(obj => obj.code === 'hbl-broker'))) {
              setTimeout(() => {
                this.$router.push({ name: 'broker-dashboard' });
              }, 1000);
            }
          }).catch(() => {
            this.message = 'User or Password Invalid';
          });
        }
      });
    },
    switchVisibility() {
      this.user.passwordFieldType = this.user.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style scoped>

</style>
