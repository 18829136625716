<template>
  <div>
    <div class="modal fade show d-block"
         id="signUpModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0">
          <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="$store.dispatch('updateSignUpModalStatus', false)">
            <span class="icon-close" />
          </button>
          <div class="modal-body p-0">
            <form
              @submit.prevent="signUpForm">
              <h1 class="text-center">
                Sign Up
              </h1>
              <p class="note mx-auto text-center"
                 style="max-width: 494px">
                Experience the world's most exclusive luxury vacation homes. Call (888) 625-7525 or create a free account and explore our homes, gain access to reviews, and more.
              </p>
              <hr class="hr-1">
              <div class="form-group has-icon">
                <i class="icon-user" />
                <input name="name"
                       v-model="user.name"
                       v-validate="'required'"
                       type="text"
                       class="form-control input-lg"
                       placeholder="First name">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('name')">{{ errors.first('name') }}</span>
              <div class="form-group has-icon">
                <i class="icon-user" />
                <input name="surname"
                       v-model="user.surname"
                       v-validate="'required'"
                       type="text"
                       class="form-control input-lg"
                       placeholder="Surname">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('surname')">{{ errors.first('surname') }}</span>
              <div class="form-group has-icon">
                <i class="icon-envelop" />
                <input name="email"
                       v-model="user.email"
                       v-validate="'required|email'"
                       type="text"
                       class="form-control input-lg"
                       placeholder="Email Address">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('email')">{{ errors.first('email') }}</span>
              <div class="form-group has-icon">
                <i class="icon-phone" />
                <vue-tel-input v-model="user.phone"
                               v-validate="'required'"
                               @country-changed="countryChanged"
                               :default-country="user.phoneCode"
                               name="phone"
                               class="form-control" />
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('phone')">{{ errors.first('phone') }}</span>
              <div class="form-group has-icon">
                <i class="icon-lock" />
                <button type="button"
                        class="showPass"
                        @click="switchVisibility">
                  {{ user.passwordFieldType == 'password' ? 'Show' : 'Hide' }}
                </button>
                <input name="password"
                       v-model="user.password"
                       v-validate="'required'"
                       :type="user.passwordFieldType"
                       ref="password"
                       class="form-control input-lg"
                       placeholder="Password">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('password')">{{ errors.first('password') }}</span>
              <div class="form-group has-icon">
                <i class="icon-location" />
                <v-select name="country"
                          v-model="user.country"
                          v-validate:user.country="'required'"
                          :options="countriesOptions"
                          autocomplete="off"
                          placeholder="Country..." />
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('country')">{{ errors.first('country') }}</span>
              <div class="custom-control custom-checkbox mb-3 text-left ml-1">
                <input type="checkbox"
                       class="custom-control-input"
                       id="terms"
                       v-model="user.terms"
                       v-validate="'required'"
                       name="terms"
                       value="accept">
                <label class="custom-control-label"
                       for="terms"><a target="_blank"
                                      href="/terms">I agree to the terms & conditions</a></label>
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('terms')">{{ errors.first('terms') }}</span>
              <span class="text-danger small mt-3 mb-3 d-block fix-margin"
                    v-if="getMessage">{{ getMessage }}</span>
              <button class="btn btn-primary btn-block btn-lg">
                SIGN UP
              </button>
              <br>
              <hr>
              <p class="note m-0 text-center">
                Already signed up?
                <a href="javascript:;"
                   @click="$store.dispatch('updateSignUpModalStatus', false); $store.dispatch('updateSignInModalStatus', true);">Sign In
                </a>
              </p>
              <p class="note text-center">
                Call <a href="tel:+8554815405">855-481-5405</a> for more information.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';

export default {
  name: 'SignUp',
  components: {
    vSelect,
  },
  data() {
    return {
      user: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        phoneCode: null,
        password: null,
        passwordFieldType: 'password',
        country: null,
      },
      message: '',
      countriesOptions: [],
    };
  },
  computed: {
    ...mapGetters(['getCountries']),
    getMessage() {
      return this.message;
    },
  },
  methods: {
    signUpForm() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$store.dispatch('requestSignUp', this.user).then(() => {
            // this.$router.push({ name: 'home' });
            window.location.reload();
          }).catch((error) => {
            this.message = error.response.data.error;
          });
        }
      });
    },
    switchVisibility() {
      this.user.passwordFieldType = this.user.passwordFieldType === 'password' ? 'text' : 'password';
    },
    countryChanged(country) {
      this.user.phoneCode = country.iso2;
    },
  },
  created() {
    this.$store.dispatch('updateSignInModalStatus', false);
    this.$store.dispatch('requestCountries').then(() => {
      this.getCountries.forEach((country) => {
        this.countriesOptions.push({ label: country.name, value: country.id });
      });
    });
  },
};
</script>


<style>

</style>
