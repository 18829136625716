<template>
  <a class="like">
    <i :class="[isFavoriteComputed ? 'fas' : 'far', 'fa-heart']"
       @click="toggleFavorites(property)" />
  </a>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PropertyListStar',
  props: {
    property: {
      type: Object,
      default: null,
    },
  },
  components: {
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  methods: {
    ...mapActions([
      'updateFavoritesProperties',
      'updateFavoritesPropertiesLocalStorage',
    ]),
    ...mapGetters([
      'getUser',
    ]),
    toggleFavorites(property) {
      if (this.getUser()) {
        this.updateFavoritesProperties(property.id);
      } else {
        this.updateFavoritesPropertiesLocalStorage(property);
      }
      this.isFavorite = !this.isFavorite;
    },
  },
  computed: {
    isFavoriteComputed() {
      return this.isFavorite;
    },
  },
  created() {
    this.isFavorite = this.property.isFavorite;
  },
};
</script>
