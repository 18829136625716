<template>
  <div>
    <div class="modal fade show d-block"
         id="loginModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Forgot Password?
            </h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="$store.dispatch('updateForgotPasswordModalStatus', false)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="forgotPasswordForm">
              <div class="form-group has-icon">
                <i class="icon-user" />
                <input v-model="email"
                       v-validate="'required|email'"
                       name="email"
                       type="text"
                       class="form-control"
                       placeholder="Email">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('email')">{{ errors.first('email') }}</span>
              <button class="btn btn-primary btn-block btn-lg">
                SEND
              </button>
            </form>
          </div>
          <p class="text-primary"
             v-if="getMessage">
            {{ getMessage }}
          </p><br>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" />
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data() {
    return {
      message: '',
      email: null,
    };
  },
  computed: {
    getMessage() {
      return this.message;
    },
  },
  methods: {
    forgotPasswordForm() {
      this.$validator.validate().then((result) => {
        if (result) {
          const formData = new FormData();
          formData.append('email', this.email);
          this.$axios.post('/v1/account/recovery', formData).then((response) => {
            this.message = response.data.message;
          }).catch(() => {
            this.message = 'Something went wrong';
          });
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
