<template>
  <div>
    <ul class="prop-list"
        v-if="propertiesData && propertiesData.length > 0">
      <li v-for="(property, index) in propertiesData"
          :key="index"
          class="col-sm-6 col-md-4 col-lg-3">
        <div class="item">
          <div
            class="locate"
            v-if="property.hightlight">
            {{ property.hightlight }}
          </div>
          <PropertyListStar :property="property" />
          <img
            :src="property.images[0].thumbnail"
            class="thumbs">
          <span>
            <router-link :to="{ name: 'property', params: { id: property.id }}">
              <div class="info">
                <h5>{{ shortTitle(property.name) }}<!--  <small v-if="property.state && property.country">{{ property.state.name }}, {{ property.country.name }}</small> --></h5>
                <p class="price">
                  <strong>${{ property.price }}</strong>/night
                </p>
                <div class="features">
                  <span><i class="far fa-user" />{{ property.maximum_capacity }}</span>
                  <span v-if="property.rooms && property.bedrooms > 0"><i class="icon-bed" />{{ property.bedrooms }}</span>
                  <span><i class="icon-bath" />{{ property.number_bathroom }}</span>
                </div>
              </div>
            </router-link>
          </span>
        </div>
      </li>
    </ul>
    <h3 v-if="propertiesData.length <= 0 && !isWishlistPage">
      No properties were found.
    </h3>
    <div class="no-favorite-properties-found"
         v-if="propertiesData.length <= 0 && isWishlistPage">
      <div class="row justify-content-center m-0">
        <div class="col-xl-6 col-lg-7 col-md-9 text-center">
          <div class="text-center d-flex justify-content-center">
            <i class="icon-star empty-state-icon" />
          </div>
          <h2 class="text-center title">
            You have no favorite properties
          </h2>
          <p class="text-center mb-lg-5 mb-3">
            You can add properties to this list using the heart button in every property.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import Flickity from 'vue-flickity';
import PropertyListStar from '@/components/PropertyListStar.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PropertyList',
  props: {
    config: {
      type: Object,
      default: null,
    },
    properties: {
      type: Array,
      default: null,
    },
  },
  components: {
    PropertyListStar,
  },
  data() {
    return {
      propertiesData: [],
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        lazyLoad: 2,
      },
      isWishlistPage: false,
    };
  },
  methods: {
    ...mapActions([
      'requestProperties',
      'requestFavoritesProperties',
      'updateFavoritesProperties',
      'updateFavoritesPropertiesLocalStorage',
    ]),
    ...mapGetters([
      'getUser',
      'getCurrentRouteName',
      'getFavoritesProperties',
      'getFavoritesPropertiesLocalStorage',
    ]),
  },
  computed: {
    computedProperties() {
      return this.propertiesData;
    },
    isFavoriteComputed() {
      return this.isFavorite;
    },
    shortTitle() {
      return (title) => {
        const length = 30;
        const trimmedString = title.length > length ? `${title.substring(0, length - 3)}...` : title;
        return trimmedString;
      };
    }
  },
  created() {
    if (this.getCurrentRouteName() === 'user-wishlist') {
      this.isWishlistPage = true;
      Promise.all([this.getUser() ? this.requestFavoritesProperties() : this.getFavoritesPropertiesLocalStorage()]).then((response) => {
        const favoriteProperties = response[0] && response[0].data ? response[0].data.data : response[0];
        if (favoriteProperties && favoriteProperties.length > 0) {
          favoriteProperties.forEach((property, index) => {
            favoriteProperties[index].isFavorite = true;
          }, favoriteProperties);
        }
        this.propertiesData = favoriteProperties;
        this.$emit('favoriteProperties', this.propertiesData);
      });
    } else {
      this.requestProperties(this.config).then((propertiesResponse) => {
        this.$emit('update', false);
        const propertiesArray = propertiesResponse.properties;
        this.pagination = propertiesResponse.pagination;
        Promise.all([this.getUser() ? this.requestFavoritesProperties() : this.getFavoritesPropertiesLocalStorage()]).then((response) => {
          const favoriteProperties = response[0] && response[0].data ? response[0].data.data : response[0];
          if (favoriteProperties && favoriteProperties.length > 0) {
            propertiesArray.forEach((property, index) => {
              propertiesArray[index].isFavorite = favoriteProperties.some(p => p.id === property.id);
            }, propertiesArray);
          }
          this.propertiesData = propertiesArray;
          this.$emit('favoriteProperties', this.propertiesData);
        });
      });
    }
  },
};
</script>

<style scoped>

</style>
