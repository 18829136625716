import Vue from 'vue';
import Router from 'vue-router';
import VueLocalStorage from 'vue-localstorage';
import Home from './views/Home.vue';
import store from './store';

[Router, VueLocalStorage].forEach(x => Vue.use(x));

function getUser() {
  return store.getters.getUser;
}

function getRol() {
  const user = getUser();
  return user && user.groups.length > 0 && user.groups[0].code;
  // return user && user.groups.length > 0 && user.groups.find(obj => obj.code === 'hbl-user') ? 'hbl-user' : ['hbl-concierge'];
}

function validateUser(to, from, next, rol) {
  if (getUser() && rol.includes(getRol())) {
    next();
  } else {
    next({ name: rol === ['hbl-concierge'] ? 'concierge-login' : 'home' });
  }
}

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: () => import(/* webpackChunkName: "AbousUs" */ './views/AboutUs.vue'),
    },
    {
      path: '/owners',
      name: 'owners',
      component: () => import(/* webpackChunkName: "Owners" */ './views/Owners.vue'),
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "Contact" */ './views/Contact.vue'),
    },
    {
      path: '/new-password',
      name: 'new-password',
      component: () => import(/* NewPassword: "SignUp" */ './views/NewPassword.vue'),
    },
    {
      path: '/property/:id',
      name: 'property',
      component: () => import(/* webpackChunkName: "Property" */ './views/Property.vue'),
    },
    {
      path: '/results',
      name: 'results',
      component: () => import(/* webpackChunkName: "Results" */ './views/Results.vue'),
    },
    {
      path: '/experiences',
      name: 'experiences',
      component: () => import(/* webpackChunkName: "Experiences" */ './views/Experiences.vue'),
    },
    {
      path: '/experience-detail',
      name: 'experience-detail',
      component: () => import(/* webpackChunkName: "ExperienceDetail" */ './views/ExperienceDetail.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import(/* webpackChunkName: "Terms" */ './views/Terms.vue'),
    },
    {
      path: '/privacy-policies',
      name: 'privacy-policies',
      component: () => import(/* webpackChunkName: "PrivacyPolicies" */ './views/PrivacyPolicies.vue'),
    },
    {
      path: '/cancellation-policies',
      name: 'cancellation-policies',
      component: () => import(/* webpackChunkName: "CancellationPolicies" */ './views/CancellationPolicies.vue'),
    },
    // Users Routes
    {
      path: '/user-profile',
      name: 'user-profile',
      component: () => import(/* webpackChunkName: "UserProfile" */ './views/users/UserProfile.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-edit-profile',
      name: 'user-edit-profile',
      component: () => import(/* webpackChunkName: "UserEditProfile" */ './views/users/UserEditProfile.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-wishlist',
      name: 'user-wishlist',
      component: () => import(/* webpackChunkName: "UserFavorites" */ './views/users/UserWishlist.vue'),
    },
    {
      path: '/user-trip-itinerary/:id',
      name: 'user-trip-itinerary',
      component: () => import(/* webpackChunkName: "UserTripItinerary" */ './views/users/UserTripItinerary.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-booking-confirmation/:id',
      name: 'user-booking-confirmation',
      component: () => import(/* webpackChunkName: "UserBookingConfirmation" */ './views/users/UserBookingConfirmation.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-booking-request',
      name: 'user-booking-request',
      component: () => import(/* webpackChunkName: "UserBookingRequest" */ './views/users/UserBookingRequest.vue'),
      beforeEnter: (to, from, next) => {
        const booking = store.getters.getBooking;
        if (booking) {
          next();
          return false;
        }
        next('/');
        return false;
      }
    },
    {
      path: '/user-message/:id',
      name: 'user-message',
      component: () => import(/* webpackChunkName: "UserMessageDetail" */ './views/users/UserMessageDetail.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-messages',
      name: 'user-messages',
      component: () => import(/* webpackChunkName: "UserMessages" */ './views/users/UserMessages.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-trips',
      name: 'user-trips',
      component: () => import(/* webpackChunkName: "UserTrips" */ './views/users/UserTrips.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-trip-charges/:id',
      name: 'user-trip-charges',
      component: () => import(/* webpackChunkName: "UserTripCharges" */ './views/users/UserTripCharges.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    // {
    //   path: '/user-trip-other-services',
    //   name: 'user-trip-other-services',
    //   component: () => import(/* webpackChunkName: "UserTripOtherServices" */ './views/users/UserTripOtherServices.vue'),
    // },
    {
      path: '/user-trip-groceries/:id',
      name: 'user-trip-groceries',
      component: () => import(/* webpackChunkName: "UserTripGroceries" */ './views/users/UserTripGroceries.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-trip-overview/:id',
      name: 'user-trip-overview',
      component: () => import(/* webpackChunkName: "UserTripOverview" */ './views/users/UserTripOverview.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-trip-travelers/:id',
      name: 'user-trip-travelers',
      component: () => import(/* webpackChunkName: "UserTripTravelers" */ './views/users/UserTripTravelers.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/edit-or-cancel-trip/:id',
      name: 'edit-or-cancel-trip',
      component: () => import(/* webpackChunkName: "UserEditAndCancelTrip" */ './views/users/UserEditAndCancelTrip.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-trip-modify/:id',
      name: 'user-trip-modify',
      component: () => import(/* webpackChunkName: "UserTripModify" */ './views/users/UserTripModify.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/user-trip-cancellation/:id',
      name: 'user-trip-cancellation',
      component: () => import(/* webpackChunkName: "UserTripCancellation" */ './views/users/UserTripCancellation.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-user', 'hbl-broker']);
      }
    },
    {
      path: '/broker-dashboard',
      name: 'broker-dashboard',
      component: () => import(/* webpackChunkName: "Dashboard" */ './views/broker/Dashboard.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-broker']);
      }
    },
    {
      path: '/broker-reservations',
      name: 'broker-reservations',
      component: () => import(/* webpackChunkName: "Reservations" */ './views/broker/Reservations.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-broker']);
      }
    },
    {
      path: '/broker-listings',
      name: 'broker-listings',
      component: () => import(/* webpackChunkName: "Listings" */ './views/broker/Listings.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-broker']);
      }
    },
    {
      path: '/broker-listings/:id',
      name: 'broker-listing',
      component: () => import(/* webpackChunkName: "Listings" */ './views/broker/Listing.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-broker']);
      }
    },
    {
      path: '/broker-charges',
      name: 'broker-charges',
      component: () => import(/* webpackChunkName: "Charges" */ './views/broker/Charges.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-broker']);
      }
    },
    {
      path: '/broker-earnings',
      name: 'broker-earnings',
      component: () => import(/* webpackChunkName: "Listings" */ './views/broker/Earnings.vue'),
      beforeEnter: (to, from, next) => {
        validateUser(to, from, next, ['hbl-broker']);
      }
    }
  ],
});

export default router;
