<template>
  <section class="newsletter">
    <figure class="newsletter__image">
      <img
        src="@/assets/images/newsletter.jpg"
        alt="Pretty hotel">
    </figure>
    <div class="newsletter__content">
      <h4>Subscribe to our newsletter</h4>
      <p>You will be informed about news, events, special offers & more.</p>
      <form action="https://capcana.us2.list-manage.com/subscribe/post"
            method="GET"
            target="_blank">
        <input
          type="text"
          name="EMAIL"
          placeholder="Enter your E-mail adress">
        <input type="hidden"
               name="u"
               value="ee6219b24c6503c7b61e657b3">
        <input type="hidden"
               name="id"
               value="c89579667e">
        <button
          type="submit"
          class="btn btn-secondary">
          SUSCRIBE
        </button>
      </form>
    </div>
  </section>
</template>

<script>
export default {

};
</script>

<style scoped>
.newsletter__content::after {
  background-image: url('~@/assets/images/home-experiences-bg.jpg');
}
</style>
