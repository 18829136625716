<template>
  <div>
    <MyHeader />
    <main id="content"
          class="inHome">
      <section class="introduction-home">
        <div class="row justify-content-center w-100"
             v-if="videoURL">
          <div class="col-lg-4 col-md-4 col-sm-4 col-6">
            <a href="javascript:;"
               class="text-center d-block"
               id="playButton"
               @click="playVideo">
              <img src="img/img-icon-play-with-cicle-lines.png"
                   alt="play-icons"
                   class="img-fluid">
            </a>
          </div>
        </div>
        <div class="introduction-home__content">
          <h1>Book your stay in Cap Cana</h1>
          <form id="searchForm"
                class="searchForm"
                @submit.prevent="searchForm">
            <div class="d-max-width justify-content-around">
              <div class="col-12 col-md-2 col-lg-3 mb-3 mb-lg-0"
                   @click="scrollToElement">
                <div class="select">
                  <i class="far fa-user" />
                  <v-select name="guests"
                            v-model="travelers"
                            :options="guestOptions"
                            :class="travelers && 'active'"
                            placeholder="Guest" />
                </div>
              </div>
              <div class="col-12 col-lg-4 col-md-auto col-md-auto mb-3 mb-lg-0 border-right"
                   @click="scrollToElement">
                <HotelDatePicker @check-in-changed="setCheckinDate"
                                 @check-out-changed="setCheckoutDate"
                                 :class="check_in && check_out && 'active'" />
              </div>
              <div class="col-12 col-lg col-md-12 col-lg-3"
                   id="searchButton">
                <button class="btn btn-block btn-primary">
                  SEARCH
                </button>
              </div>
            </div>
          </form>
        </div>
        <flickity v-if="slides.SetImages && videoURL == ''"
                  :options="flickityOptions"
                  class="slideHome"
                  ref="flickity">
          <div v-for="(slide, index) in slides.SetImages"
               :key="index"
               class="slide"
               :data-flickity-bg-lazyload="slide"
               :style="{ 'background-image': 'url(' + slide + ')' }" />
        </flickity>
      </section>
      <section class="home__features container-fluid mt-4">
        <div class="row">
          <figure class="col-md-6 col-xl-5 home__features__image">
            <img
              src="@/assets/images/home-first-section.jpg"
              alt="Excelent hotel">
          </figure>
          <div class="col-md-6 col-xl-5 home__features__text">
            <h4>
              EXCLUSIVE EXPERIENCE SPECIALIST
            </h4>
            <p>Immerse yourself in a world of possibilities and let us make your trip a unique experience. Our experience team will help with every detail of your experience so you can be worry free.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 col-xl-4 ml-auto home__features__text home__features__text-second">
            <h4>A TAILOR-MADE TRIP FOR YOUR CONVENIENCE</h4>
            <p>Your Experience Specialist will be your local connection. Their job is to take care of the details that will make your stay a memorable experience.</p>
            <router-link
              to="/results"
              class="btn btn-secondary">
              view properties
            </router-link>
          </div>
          <figure class="row collage col-md-7 col-xl-7 home__features__collage">
            <img
              src="@/assets/images/home-first-section-collage.jpg"
              alt="Beaches collage">
          </figure>
        </div>
      </section>
      <section class="home__experiences row no-gutters">
        <img
          src="@/assets/images/home-experiences.jpg"
          alt="Awesome hotel experience"
          class="col-lg-6 col-xl-7">
        <div class="home__experiences__content col-lg-6 col-xl-5">
          <h2>EXPERIENCES</h2>
          <h4>Meet a world of experiences inside and near Cap Cana.</h4>
          <p>Our Experience Specialists will be available to recommend and even elaborate those activities or excursions that can connect with your interests and tastes, whether in the cultural, gastronomic, historical, sports order, connection with the community, visit to National Parks or activities such as: hiking, bird watching, diving, mountain bike rides.</p>
        </div>
      </section>
      <section class="home__service row no-gutters">
        <div class="home__service__content col-lg-6 col-xl-7">
          <h4>LUXURY CONCIERGE SERVICES</h4>
          <p>
            HomeBeLike has a seasoned team of specialists with luxury
            experience from around the world. Our team comes with experience  fromthe best brands in hospitality such as SLH, Preferred, Relais & Cheaux, and many more. Our Experience Specialists will ensure your experiences are something truly unique.
          </p>
        </div>
        <img
          src="@/assets/images/home-service.jpg"
          alt="Excellent hotel service"
          class="col-lg-6 col-xl-5">
      </section>
      <section class="home__properties container">
        <h2>NEW PROPERTIES FOR RENT</h2>
        <h4>Find the property of your dreams</h4>
        <hr>
        <PropertyList :config="{ limit: 4, sort_field: 'created_at', sort_order: 'DESC'}" />
      </section>
      <newsletter />
    </main>
    <MyFooter />
  </div>
</template>

<script>
// eslint-disable-next-line
import Flickity from 'vue-flickity';
import vSelect from 'vue-select';
import PropertyList from '@/components/PropertyList.vue';
import HotelDatePicker from 'vue-hotel-datepicker';
import { mapActions } from 'vuex';
import Newsletter from '../components/Newsletter.vue';

export default {
  name: 'Home',
  components: {
    Flickity,
    vSelect,
    PropertyList,
    HotelDatePicker,
    Newsletter,
  },
  data() {
    return {
      scrollListener: {},
      slides: {},
      categories: {},
      check_in: null,
      check_out: null,
      destination: [],
      destinationOptions: [],
      travelers: {},
      guestOptions: [
        { label: '1+ Travelers', value: '1' },
        { label: '2+ Travelers', value: '2' },
        { label: '3+ Travelers', value: '3' },
        { label: '4+ Travelers', value: '4' },
        { label: '5+ Travelers', value: '5' },
        { label: '6+ Travelers', value: '6' },
        { label: '7+ Travelers', value: '7' },
        { label: '8+ Travelers', value: '8' },
        { label: '9+ Travelers', value: '9' },
        { label: '10+ Travelers', value: '10' },
      ],
      flickityOptions: {
        initialIndex: 5,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        lazyLoad: 1,
        autoPlay: 5000
      },
      flickityOptionsCategories: {
        initialIndex: 5,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        lazyLoad: 1,
        autoPlay: 5000
      },
      videoURL: ''
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'CapCana Rentals',
    // all titles will be injected into this template
    titleTemplate: '%s | Exclusive experience specialists',
    meta: [
      { name: 'description', content: 'Immerse yourself in a world of possibilities around the world and let us make your vacation, business retreat or business trip a unique' },
      // Schema.org markup for Google+
      { itemprop: 'name', content: 'CapCana Rentals | Exclusive experience specialists' },
      { itemprop: 'description', content: 'Immerse yourself in a world of possibilities around the world and let us make your vacation, business retreat or business trip a unique' },
      { itemprop: 'image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Twitter Card data
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'CapCana Rentals | Exclusive experience specialists' },
      { name: 'twitter:description', content: 'Immerse yourself in a world of possibilities around the world and let us make your vacation, business retreat or business trip a unique' },
      // Twitter summary card with large image must be at least 280x150px
      { name: 'twitter:image:src', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Open Graph data
      { property: 'og:title', content: 'CapCana Rentals | Exclusive experience specialists' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://homebelike.com/' },
      { property: 'og:image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      { property: 'og:description', content: 'Immerse yourself in a world of possibilities around the world and let us make your vacation, business retreat or business trip a unique' },
    ]
  },
  methods: {
    ...mapActions([
      'requestHomeSlides',
      'requestCategories',
      'requestPlaces',
    ]),
    handleScroll() {
      const scrollTopHeight = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if (scrollTopHeight >= 100 && this.$route.name === 'home') document.getElementById('header').classList.remove('inHome');
      else document.getElementById('header').classList.add('inHome');

      // Adding class to header and form on scroll
      const header = document.getElementById('header');
      if (scrollTopHeight >= 140 && this.$route.name === 'home') {
        header.classList.add('white-header');
      } else {
        header.classList.remove('white-header');
      }
    },
    scrollToElement() {
      const scrollTopHeight = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if (scrollTopHeight < 593) {
        this.$scrollTo('#searchForm', 500, { offset: -150 });
      }
    },
    onSearch(search, loading = null) {
      loading(true);
      this.requestPlaces(escape(search)).then((response) => {
        this.destinationOptions = response;
        loading(false);
      });
    },
    searchForm() {
      if (this.check_in && this.check_out) {
        this.$localStorage.set('check_in', this.check_in);
        this.$localStorage.set('check_out', this.check_out);
      }

      this.$validator.validate().then((result) => {
        if (result) {
          this.$router.push({
            path: 'results',
            query: {
              destination: JSON.stringify(this.destination),
              travelers: JSON.stringify(this.travelers),
            },
          });
        }
      });
    },
    setCheckinDate(checkIn) {
      this.check_in = checkIn;
    },
    setCheckoutDate(checkOut) {
      this.check_out = checkOut;
    },
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
    playVideo() {
      const playButton = document.getElementById('playButton');
      playButton.classList.remove('d-block');
      playButton.classList.add('d-none');
      const imageVideoBackground = document.getElementById('imageVideoBackground');
      imageVideoBackground.classList.add('d-none');
      const video = document.getElementById('video');
      video.classList.remove('d-none');
      video.play();
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.requestPlaces('').then((response) => { this.destinationOptions = response; });
    this.requestHomeSlides().then((response) => { this.slides = response; });
    this.requestCategories().then((response) => { this.categories = response; });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.justify-content-around {
  justify-content: space-around;
}
.home__experiences__content {
  background-image: url('~@/assets/images/home-experiences-bg.jpg');
}
.resizeTemporal {
  width: 120px!important;
  height: 120px!important;
}
.errorBorder {
  border: 1px solid red;
}

</style>
