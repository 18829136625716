import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VeeValidate from 'vee-validate';
import axios from 'axios';
import moment from 'moment';
import VueTelInput from 'vue-tel-input';
import VueMeta from 'vue-meta';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import MyHeader from '@/components/Header.vue';
import MyFooter from '@/components/Footer.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import App from './App.vue';

const VueScrollTo = require('vue-scrollto');

// VUE COMPONENT
Vue.component('MyHeader', MyHeader);
Vue.component('MyFooter', MyFooter);

// VUE USE
[i18n, VueTelInput, VueScrollTo, VueMeta].forEach(x => Vue.use(x));
Vue.use(VeeValidate, {
  events: 'blur'
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbPWZUR32etu1vOAEficDR3Haec7YDIig',
    libraries: 'places',
  },
});
// CHANGE DEFAULT LANGUEAGE BASED ON DOMAIN NAME
const domain = window.location.hostname;
i18n.locale = domain === 'localhost' ? 'es' : 'en';

Vue.config.productionTip = false;

// AXIOS CUSTOM SETTINGS

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

if (Vue.localStorage.get('token')) {
  axios.defaults.headers.common = { Authorization: `Bearer ${Vue.localStorage.get('token')}`, lang: store.getters.getLanguage };
}

// WHEN A REQUEST IS MAKE

// before a request is made start the nprogress
// axios.interceptors.request.use((config) => {
//   store.commit('setIsLoading', true);
//   return config;
// });

// IF 401 REFRESH TOKEN

axios.interceptors.response.use(response => response, (error) => {
  if (error.response.status === 401) {
    const token = new FormData();
    token.append('token', Vue.localStorage.get('token'));
    axios.post('/api/refresh', token).then((response) => {
      Vue.localStorage.set('token', response.data.token);
      axios.defaults.headers.common = { Authorization: `Bearer ${Vue.localStorage.get('token')}`, lang: store.getters.getLanguage };
      router.go();
    }).catch(() => {
      Vue.localStorage.remove('user');
      Vue.localStorage.remove('token');
      router.push('/');
    });
  }
  return Promise.reject(error);
});

Vue.prototype.$axios = axios;

// END AXIOS CUSTOM SETTINGS

// VUE FILTERS

Vue.filter('formatDate', value => moment(String(value)).format('YYYY / MM / DD'));
Vue.filter('beatyDate', value => moment(String(value)).format('DD MMM YYYY'));
Vue.filter('numFormat', numFormat(numeral));

new Vue({
  axios,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
