<template>
  <header id="header"
          v-if="!$route.path.includes('/broker-')"
          :class="[getCurrentRouteName === 'home' ? 'inHome' : 'white-header']">
    <div class="container">
      <div class="d-flex d-lg-none justify-content-lg-center pb-lg-4 w-100 border-bottom">
        <router-link class="logo"
                     :to="{ name: getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-concierge') ? 'concierge-dashboard' : 'home' }">
          <img src="/img/hbl-new-logo-black.png">
          <img class="alt"
               src="/img/hbl-new-logo.png">
        </router-link>
      </div>
      <nav :class="{ 'main-nav': true, open: toggleMenu }">
        <div class="user-cpanel"
             v-if="getUser">
          <a href="javascript:;"
             class="profile"
             @click="toggleMenu = !toggleMenu"
             v-click-outside="outside">
            <span class="pic"><img :src="getUser.avatar ? getUser.avatar.path : '/img/user-thumb.jpg'"></span>
            <span class="name">{{ getUser.name }} {{ getUser.surname }}</span>
            <i class="icon-cheveron-down" />
          </a>
          <ul id="user-menu"
              :class="{ smoothTransition : toggleMenu }">
            <li v-if="getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-user') || getUser.groups.find(obj => obj.code === 'hbl-broker')">
              <router-link :to="{ name: 'user-trips' }">
                My Trips
              </router-link>
            </li>
            <li v-if="getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-user') || getUser.groups.find(obj => obj.code === 'hbl-broker')">
              <router-link :to="{ name: 'user-wishlist' }">
                Wishlist
              </router-link>
            </li>
            <li v-if="getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-user') || getUser.groups.find(obj => obj.code === 'hbl-broker')">
              <router-link :to="{ name: 'user-messages' }">
                Messages
              </router-link>
            </li>
            <li v-if="getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-user') || getUser.groups.find(obj => obj.code === 'hbl-broker')">
              <router-link :to="{ name: 'user-profile' }">
                Profile & Account
              </router-link>
            </li>
            <li v-if="getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-broker')">
              <router-link :to="{ name: 'broker-dashboard' }">
                Dashboard
              </router-link>
            </li>
            <li>
              <a class="text-danger"
                 href="javascript:;"
                 @click="logOut">Sign Out</a>
            </li>
          </ul>
        </div>
        <ul class="main-menu">
          <li
            v-if="
              (getUser
                &&
                getUser.groups.length>
                0 && !getUser.groups.find(obj => obj.code === 'hbl-concierge')) || !getUser">
            <router-link :to="{ name: 'results' }">
              Properties
            </router-link>
          </li>
          <li
            v-if="
              (getUser
                &&
                getUser.groups.length>
                0 && !getUser.groups.find(obj => obj.code === 'hbl-concierge')) || !getUser">
            <a href="http://capcana.com/"
               target="_blank">
              Cap Cana
            </a>
          </li>
          <li
            v-if="
              (getUser
                &&
                getUser.groups.length>
                0 && !getUser.groups.find(obj => obj.code === 'hbl-concierge')) || !getUser">
            <router-link :to="{ name: 'contact' }">
              Contact
            </router-link>
          </li>
          <li class="ham-logo">
            <router-link class="logo"
                         :to="{ name: getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-concierge') ? 'concierge-dashboard' : 'home' }">
              <img src="/img/hbl-new-logo-black.png">
              <img class="alt"
                   src="/img/hbl-new-logo.png">
            </router-link>
          </li>
          <li class="wishlist"
              v-if="((getUser && getUser.groups.length > 0 && !getUser.groups.find(obj => obj.code === 'hbl-concierge')) || !getUser) || !getUser">
            <router-link :to="{ name: 'user-wishlist' }">
              <div class="number"
                   v-if="getUser && computedFavoritesPropertiesCounter > 0">
                {{ computedFavoritesPropertiesCounter }}
              </div>
              <div class="number"
                   v-if="!getUser && computedFavoritesPropertiesCounter && computedFavoritesPropertiesCounter.length">
                {{ computedFavoritesPropertiesCounter.length }}
              </div> Wishlist
            </router-link>
          </li>
          <li class="contact link"
              v-if="!getUser">
            <a href="javascript:;"
               data-toggle="modal"
               data-target="#loginModal"
               @click="$store.dispatch('updateSignInModalStatus', true)"> Sign in</a>
          </li>
          <li class="social">
            <a
              href="https://instagram.com/vivecapcana/"
              target="_blank"
              rel="noopener noreferrer"
              class="social">
              <i class="icon-instagram" />
            </a>
            <a
              href="https://www.facebook.com/vivecapcana/"
              target="_blank"
              rel="noopener noreferrer"
              class="social">
              <i class="fab fa-facebook-square" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCy6Ne8ONZTNmNCAGyZZYDpg"
              target="_blank"
              rel="noopener noreferrer"
              class="social">
              <i class="icon-youtube" />
            </a>
          </li>
        </ul>
        <ul class="main-menu"
            v-if="getUser && getUser.groups.length > 0 && getUser.groups.find(obj => obj.code === 'hbl-concierge')">
          <li>
            <router-link :to="{ name: 'concierge-dashboard' }"
                         :class="{ active: this.$route.name === 'concierge-reservation-overview' || this.$route.name === 'concierge-reservation-travelers' || this.$route.name === 'concierge-reservation-itinerary' || this.$route.name === 'concierge-reservation-groceries' || this.$route.name === 'concierge-reservation-charges' }">
              RESERVATIONS
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'concierge-messages' }"
                         :class="{ active: this.$route.name === 'concierge-message' }">
              INBOX <span class="badge badge-danger"
                          v-if="computedMessageCounter">{{ computedMessageCounter }}</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <button class="btn-nav"
              @click="showMenu();">
        <span />
      </button>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      toggleMenu: false,
      messagesCounter: 0,
      favoritesPropertiesCounter: 0
    };
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getCurrentRouteName',
    ]),
    computedMessageCounter() {
      return this.$store.getters.getMessageCounter;
    },
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
    computedFavoritesPropertiesCounter() {
      return this.getUser ? this.getFavoritesPropertiesCounter() : this.getFavoritesPropertiesLocalStorage();
    }
  },
  methods: {
    ...mapActions([
      'updateLanguage',
      'updateCurrentRouteName',
      'requestFavoritesProperties',
      'requestLogOut'
    ]),
    ...mapGetters([
      'getFavoritesPropertiesCounter',
      'getFavoritesPropertiesLocalStorage',
    ]),
    outside() {

    },
    updateLang() {
      this.updateLanguage(this.languageSelected);
    },
    onUpdate() {
      this.toggleMenu = false;
    },
    showMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    logOut() {
      this.requestLogOut().then(() => {
        this.$router.push('/');
        window.location.reload();
      });
    }
  },
  created() {
    this.updateCurrentRouteName(this.$route.name);
  },
  mounted() {
    if ((this.getUser && this.getUser.groups.length > 0 && this.getUser.groups.find(obj => obj.code === 'hbl-concierge'))) {
      this.$axios.get('/v1/account/messages').then((response) => {
        this.messagesCounter = response.data.data.new_messages;
        this.$store.commit('setMessageCounter', response.data.data.new_messages);
      });
    }
    Promise.all([this.getUser ? this.requestFavoritesProperties() : this.getFavoritesPropertiesLocalStorage()]).then((response) => {
      const favoriteProperties = response[0] && response[0].data ? response[0].data.data : response[0];
      if (favoriteProperties) {
        favoriteProperties.forEach((property, index) => {
          favoriteProperties[index].isFavorite = true;
        }, favoriteProperties);
      }

      this.favoritesPropertiesCounter = favoriteProperties && favoriteProperties.length ? favoriteProperties.length : 0;
      // this.$nextTick(() => {
      //   this.$store.commit('setIsLoading', false);
      // });
    });
    // this.$store.commit('setIsLoading', false);
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.eventOnClick = (event) => {
          const dragging = el.getAttribute('data-dragging');
          if (!(el === event.target || el.contains(event.target)) && !dragging) vnode.context[binding.expression](event);
        };
        document.addEventListener('click', el.eventOnClick);
      },
      unbind(el) {
        document.removeEventListener('click', el.eventOnClick);
      },
    },
  },
  watch: {
    $route(to) {
      this.updateCurrentRouteName(this.$route.name);
      if (to.name === 'results') {
        this.$store.commit('setIsLoading', true);
      }
      this.toggleMenu = false;
      if (this.$store.getters.getCurrentRouteName !== 'results' && this.$store.getters.getCurrentRouteName !== 'property' && this.$store.getters.getCurrentRouteName !== 'user-booking-request') {
        this.$localStorage.remove('check_in');
        this.$localStorage.remove('check_out');
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
